import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppDispatch } from '../store'
import { updateChainId } from './actions'

export function useGetChainId(): number {
  return useSelector((state: AppState) => state.network.chainId)
}

export const useUpdateChainId = (): ((chainId: number) => void) => {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (chainId: number): void => {
      dispatch(updateChainId({ chainId: chainId }))
    },
    [dispatch]
  )
}
