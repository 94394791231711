import { API_HOST } from '../constants/endpoints'
import agent from './agent'

export async function postVerifyEmailToken(email_token: string): Promise<Record<string, any>> {
  // const res = await fetch(`${API_HOST}/login/verify_email_token`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': `bearer ${email_token}`
  //   },
  // })

  const res = await agent.post(`${API_HOST}/login/verify_email_token`).set('Authorization', `Bearer ${email_token}`)
  // const data: any = await res.json()
  if (res) {
    return res
  }

  throw new Error('failed to verfied email token')
}
