import { combineReducers} from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import sessionReducer from '../session/reducer'
import networkReducer from '../network/reducer'
import userReducer from '../user/reducer'

const reducers = combineReducers({
    session: sessionReducer,
    network: networkReducer,
    user: userReducer,
})

const preloadedState = {}
const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([]),
    devTools: true,
    preloadedState,
})

export type AppState = ReturnType<typeof store.getState> 
export type AppDispatch = typeof store.dispatch
export default store