import { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
// import { useWeb3React } from '@web3-react/core'
// import { utils } from 'ethers'
import {
  useMediaQuery,
  useTheme,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  createStyles,
  Theme,
  makeStyles,
  Grid,
  CircularProgress,
} from '@material-ui/core'

import GradientCard from '../../common/GradientCard'
import GradientBackground from '../../common/GradientBackground'
import ActionBoard from '../../common/ActionBoard'
import { MainButton } from '../../common/buttons'
import {
  TitleDiv,
  TitleFront,
  TitleIcon,
  TitleTextDiv,
  TitleBackContainer,
  TitleBack,
  Basic,
  BasicContainer,
  BasicText,
  BasicBottom,
  BasicBottomLeft,
  BasicBottomRight,
  PoolContainer,
  PoolFlex,
  PoolFlexCenter,
  Button,
  SubButton,
  Pool,
  AddressInfoBox,
  PoolFlexWrapper,
  ContentFlex,
} from './styled'
// import { MapIcon } from '../../common/MapIcon'
import TransmuteCoin from '../../assets/symbol/coin.png'
import EnhancedTableWrapper from '../../common/EnhancedTableWrapper'
import { InvestDetailTitle, IInvestDetail } from '../../constants/data_interface'
import { useOutletContext } from 'react-router-dom'
import { Plus } from 'react-feather'
import numeral from 'numeral'
import { BorrowingStauts, RepaymentStatus } from '../../constants/data_interface'

export interface ITransactionData {
  name: string
  email: string
  amount: number
  time: string
}

const MockData: IInvestDetail[] = []

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '90.625vw',
      height: '88.15vh',
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    titleButtonExit: {
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '8px',
      '&:hover': {
        borderRadius: '16px',
      },
    },
    titleMargin: {
      marginBottom: 10,
    },
    disableTitleButtonExit: {
      color: 'rgba(255, 255, 255, 0.2)',
      border: `1px solid rgba(255, 255, 255, 0.2)`,
      backgroundColor: 'transparent',
      marginRight: '8px',
    },
    transStatusDetial: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '16px 0 16px 16px',
      [theme.breakpoints.down(960)]: {
        padding: '16px 0 0 0',
      },
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      maxWidth: 280,
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '80px',
      },
    },
    disableText: {
      color: 'rgba(255, 255, 255, 0.2)',
    },
    dividerVertical: {
      width: '1px',
      backgroundColor: theme.palette.text.primary,
      opacity: '60%',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
  })
)

const Home = () => {
  const context: Record<string, any> = useOutletContext()
  const classes = useStyles()
  const [isDrawerOpen, setDrawerOpen] = useState<boolean[]>([false, false, false, false])

  const tld = useMemo((): number => {
    if (context && context.borrowingData.length > 0) {
      const amountArray = context.borrowingData.map((borrow: Record<string, any>) => {
        if (borrow['status'] === BorrowingStauts.ONGOING) return borrow['amount']
        else return 0
      })
      return amountArray.reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
    } else {
      return 0
    }
  }, [context])

  const interestAmount = useMemo((): number => {
    if (context && context.repaymentData.length > 0) {
      const amountArray = context.repaymentData.map((repayment: Record<string, any>) => {
        if (repayment['status'] === RepaymentStatus.PENDING) return repayment['amount']
        else return 0
      })
      return amountArray.reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
    } else {
      return 0
    }
  }, [context])

  const insuranceAmount = useMemo((): number => {
    if (context && context.insuranceData.length > 0) {
      const amountArray = context.insuranceData.map((insurance: Record<string, any>) => {
        if (insurance['status'] === RepaymentStatus.PENDING) return insurance['amount']
        else return 0
      })
      return amountArray.reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
    } else {
      return 0
    }
  }, [context])

  const penaltyAmount = useMemo((): number => {
    if (context && context.penaltyData.length > 0) {
      const amountArray = context.penaltyData.map((penalty: Record<string, any>) => {
        if (penalty['status'] === RepaymentStatus.PENDING) return penalty['amount']
        else return 0
      })
      return amountArray.reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
    } else {
      return 0
    }
  }, [context])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.informationBox}>
          <TitleDiv>
            <TitleFront>
              <TitleIcon src={TransmuteCoin} />
              <TitleTextDiv>
                <Typography variant="body2" className={classes.greyHeader}>
                  NAOS
                </Typography>
                <Typography variant="h3">{context.assetData.name} Assets Management</Typography>
              </TitleTextDiv>
            </TitleFront>
          </TitleDiv>

          <Pool>
            <Typography variant="h2" className={classes.titleMargin}>
              Loan Records
            </Typography>
            <GradientBackground width="100%" height="184px">
              <BasicContainer>
                <GradientCard height="80px">
                  <Basic>
                    <BasicText style={{ alignItems: 'center' }}>
                      <Typography variant="h2">{`$ ${numeral(tld).format('0,0')}`}</Typography>
                      <Typography variant="h4">Total Loan Disburse</Typography>
                    </BasicText>
                    <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                    <BasicText>
                      <Typography variant="h2">{`$ ${numeral(interestAmount).format('0,0')}`}</Typography>
                      <Typography variant="h4">Interest</Typography>
                    </BasicText>
                    <Plus style={{ color: 'white' }} />
                    <BasicText>
                      <Typography variant="h2">{`$ ${numeral(insuranceAmount).format('0,0')}`}</Typography>
                      <Typography variant="h4">Insurance</Typography>
                    </BasicText>
                    <Plus style={{ color: 'white' }} />
                    <BasicText>
                      <Typography variant="h2">{`$ ${numeral(penaltyAmount).format('0,0')}`}</Typography>
                      <Typography variant="h4">Penalty</Typography>
                    </BasicText>
                    <Typography variant="h1">=</Typography>
                    <BasicText>
                      <Typography variant="h2">{`$ ${numeral(interestAmount + insuranceAmount + penaltyAmount).format(
                        '0,0'
                      )}`}</Typography>
                      <Typography variant="h4">Period Total Amount Due</Typography>
                    </BasicText>
                  </Basic>
                </GradientCard>
                <BasicBottom>
                  <AddressInfoBox>
                    <Typography variant="h4" style={{ width: '132px' }}>
                      Payment Address
                    </Typography>
                    <Typography variant="h4">
                      {context.assetData.paymentAddress}
                    </Typography>
                  </AddressInfoBox>
                  <AddressInfoBox>
                    <Typography variant="h4" style={{ width: '132px' }}>
                      Repayment Address
                    </Typography>
                    <Typography variant="h4">
                      {context.assetData.repaymentAddress}
                    </Typography>
                  </AddressInfoBox>
                </BasicBottom>
              </BasicContainer>
            </GradientBackground>
          </Pool>

          <Pool>
            <PoolFlex>
              <PoolFlexWrapper style={{ width: '100%' }}>
                <Typography variant="h2">Investment Detail</Typography>
                <div style={{ height: '10px' }} />
                <EnhancedTableWrapper
                  height={'528px'}
                  tableTitle={InvestDetailTitle}
                  data={[]}
                  initialOrderBy={'amount'}
                  dataStatus={'repayment'}
                />
                {/* <GradientBackground width="100%" height={'650px'}>
                  <Investments
                    data={testInput}
                    chainId={chainId ?? ChainId.MAINNET}
                    // openDetail={() => {
                    //   setInvestmentsOpen(true)
                    // }}
                    // setDetail={(data: ITransactionData) => {
                    //   setInvestmentDetailData(data)
                    // }}
                  />
                </GradientBackground> */}
              </PoolFlexWrapper>
            </PoolFlex>
            <div className={classes.bottomSpace}></div>
          </Pool>

          <div className={classes.bottomSpace}></div>
        </div>
      </div>
    </>
  )
}

export default Home
