import { SET_SESSION_EXPIRED, updateSession } from './actions'
import { createReducer } from '@reduxjs/toolkit'

const initialState: Record<string, boolean> = {
  expired: false,
}

export default createReducer(initialState, (builder) =>
  builder.addCase(updateSession, (state, action) => {
    const { type, value } = action.payload
    switch(type){
        case SET_SESSION_EXPIRED:
            state.expired = value
            break
        default:
            break
    }
  })
)
