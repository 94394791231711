import { API_HOST } from '../constants/endpoints'
import agent from './agent'

export async function postLogout(): Promise<void> {
  const res = await agent.post(`${API_HOST}/login/logout`)
  if (res && res.status === 200) {
    return 
  }
  throw new Error('failed to setup totp')
}
