import { createAction } from '@reduxjs/toolkit'
import { apiUrl } from '../../constants'

export const EDIT_USER_PENDING = 'EDIT_USER_PENDING'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'
export const FETCH_USER_PENDING = 'FETCH_USER_PENDING'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'


export const userInfo = createAction<{ type: string; payload?: any }>('userInfo')

