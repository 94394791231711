import { Navigate, Route, Routes } from 'react-router-dom'
import Signin from './Signin'
import Home from './Home'
import History from './History'
import { AppWrapper } from './App-styled'
import Auth from './Auth'
import EmailCheck from './Signin/EmailCheck'
import HandleLogin from './Signin/HandleLogin'
import OTP from './Signin/OTP'
import OtpSetup from './Signin/OtpSetup'
import OtpExplain from './Signin/OtpExplain'
import { useEffect } from 'react'

import { pageTitle } from '../constants'
import RequireAuth from '../common/RequireAuth'

function App() {
  // useEffect(() => {
  //   document.title = pageTitle
  // })

  return (
    <AppWrapper>
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Signin />} />
        <Route path="check_email" element={<EmailCheck />} />
        <Route path="login_handler/*" element={<HandleLogin />} />
        <Route path="otp/*">
          <Route path="*" element={<Navigate to="/otp" replace />} />
          <Route path="" element={<OTP />} />
          <Route path="explain" element={<OtpExplain />} />
          <Route
            path="setup"
            element={
              <RequireAuth>
                <OtpSetup />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="auth/*" element={<Auth />}>
          <Route path="*" element={<Navigate to="/auth/home" replace />} />
          <Route path="home" element={<Home />} />
          <Route path="history" element={<History />} />
        </Route>
      </Routes>
    </AppWrapper>
  )
}

export default App
