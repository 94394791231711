import { FC, useEffect, useState, useMemo } from 'react'
import { LogOut } from 'react-feather'

import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  CircularProgress,
  Grid,
  Menu,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import GradientBackground from '../GradientBackground'
import { ContainerButton } from './styled'
import { postLogout } from '../../utils/postLogout'
import { useNavigate } from 'react-router-dom'
import { useGetChainId, useUpdateChainId } from '../../redux/network/hooks'
import { ChainId } from '../../constants/blockchain'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: '16px !important',
      '&:before': {
        borderRadius: '16px !important',
      },
    },
    networkButon: {
      width: '120px',
      [theme.breakpoints.down(960)]: {
        width: '60px',
      },
    },
    ddl: {
      top: '80px !important',
    },
    wrongBorder: {
      border: '2px solid #B00020',
    },
  })
)

const displayChainName = (chainId: number): string => {
  switch (chainId) {
    case ChainId.BSC:
      return 'BSC'
    case ChainId.RINKEBY:
      return 'Rinkeby'
    case ChainId.MAINNET:
      return 'Ethereum'
    default:
      return 'BSC'
  }
}

const AccountStatus: FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const chainId = useGetChainId()
  const setChainId = useUpdateChainId()

  const handleChainId = () => {
    if (chainId === ChainId.BSC) {
      setChainId(ChainId.MAINNET)
    } else {
      setChainId(ChainId.BSC)
    }
  }

  const handleLogout = async () => {
    try {
      await postLogout()
      navigate('../../../', { replace: true })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Grid style={{ display: 'flex', marginRight: '3rem', gap: '1rem' }}>
      {/* <GradientBackground
        onClick={handleChainId}
        classes={{ root: classes.box, background: classes.box }}
        borderRadius="16px"
        width={'100px'}
        height={'32px'}
      >
        <ContainerButton>
          <Typography color="textPrimary">{displayChainName(chainId)}</Typography>
        </ContainerButton>
      </GradientBackground> */}
      <GradientBackground
        onClick={handleLogout}
        classes={{ root: classes.box, background: classes.box }}
        borderRadius="16px"
        width={'120px'}
        height={'32px'}
      >
        <ContainerButton>
          <LogOut color="#fff" width={'16px'} />
          <Typography color="textPrimary">LOGOUT</Typography>
        </ContainerButton>
      </GradientBackground>
    </Grid>
  )
}

export default AccountStatus
