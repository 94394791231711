import { API_HOST } from '../constants/endpoints'
import agent from './agent'

export async function postSetupTOTP(): Promise<Record<string, any>> {
  const res = await agent.post(`${API_HOST}/login/setup_totp`)
  if (res && res.body) {
    return res.body
  }
  throw new Error('failed to setup totp')
}
