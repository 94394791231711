import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './page_common/App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme-styled'
import { MuiProvider, StyledProvider } from './theme'
import { Provider as StoreProvider } from 'react-redux'
import store from './redux/store'

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MuiProvider>
            <StyledProvider>
              <App />
            </StyledProvider>
          </MuiProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
