import { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useFetchUser } from '../../redux/user/hooks'
import { useGetSessionExpired, useSetSessionExpired } from '../../redux/session/hooks'

import { LoginWrapper, LogoBox, SignInPanel, LoadingWrapper, TextSpan } from '../../page_common/Signin/styled'
import ImageWhiteLogo from '../../assets/logo/logo-naos.svg'
import { theme } from '../../theme-styled'
import { BounceLoader } from 'react-spinners'

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const fetchUser = useFetchUser()
  const setSessionExpired = useSetSessionExpired()
  const getSessionExpired = useGetSessionExpired()
  const [isLoading, setIsLoaing] = useState<boolean>(true)
  const [fetchStatus, setFetchStatus] = useState<string>('')

  setSessionExpired(false)

  useEffect(() => {
    fetchUser().then(setFetchStatus).catch(setFetchStatus)
  }, [])

  useEffect(() => {
    if (fetchStatus === 'Success' && !getSessionExpired) {
      setIsLoaing(false)
    }
  }, [fetchStatus])

  if (getSessionExpired) {
    setIsLoaing(true)
    const pathLen = location.pathname.split('/').length

    let pathPrefix = ''
    for (let i = 0; i < pathLen; i++) {
      pathPrefix += '../'
    }

    navigate(`${pathPrefix}?error=expired`, { replace: true })
  }

  return isLoading ? (
    <LoginWrapper>
      <LogoBox src={ImageWhiteLogo} />
      <SignInPanel>
        <LoadingWrapper>
          <BounceLoader color={theme.loading} size={60} />
          <TextSpan>Loading...</TextSpan>
        </LoadingWrapper>
      </SignInPanel>
    </LoginWrapper>
  ) : (
    children
  )
}

export default RequireAuth
