import {API_HOST} from "../constants/endpoints"

export async function postSendEmail(email:string): Promise<Record<string, any>> {
  const postData = {
    email
  }
  const res = await fetch(`${API_HOST}/login/send_email_token`, {
    method: 'POST',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data: any = await res.json()
  if (data) {
    return data
  }

  throw new Error('failed to post kyc info')
}