import { ReactNode, useMemo } from 'react'
import { Link } from './styled'
import { ChainId } from '../../constants/blockchain'

export const TransactionLink = ({
  hash,
  chainId = ChainId.BSC,
  children,
}: {
  hash: string
  chainId?: number
  children?: ReactNode
}): JSX.Element => {
  const href = useMemo((): string => {
    if (chainId === ChainId.MAINNET) {
      return `https://etherscan.io/tx/${hash}`
    } else if (chainId === ChainId.BSC) {
      return `https://bscscan.com/tx/${hash}`
    } else return '#'
  }, [hash, chainId])
  return (
    <Link href={href} target="_blank">
      {children}
    </Link>
  )
}

const ExternalLink = ({ href, children }: { href: string; children: JSX.Element }): JSX.Element => {
  return <Link href={href}>{children}</Link>
}

export default ExternalLink
