import request from 'superagent'
import store from '../redux/store'
import { updateSession } from '../redux/session/actions'

const agent = request
  .agent()
  .withCredentials()
  .on('error', (error: any) => {
    if (error.status === 401) {
      store.dispatch(updateSession({ type: 'SET_SESSION_EXPIRED', value: true }))
    }
  })

export default agent
