import { useCallback } from 'react'
import { AppState, AppDispatch } from '../store'
import { useSelector, useDispatch } from 'react-redux'
import { userInfo } from './actions'
import {
  EDIT_USER_PENDING,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  FETCH_USER_PENDING,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
} from './actions'
import agent from '../../utils/agent'
import { API_HOST } from '../../constants/endpoints'
import { updateSession } from '../session/actions'

function fetchUserPending() {
  return {
    type: FETCH_USER_PENDING,
  }
}

function editUserPending() {
  return {
    type: EDIT_USER_PENDING,
  }
}

function editUserSuccess(payload) {
  return {
    type: EDIT_USER_SUCCESS,
    payload,
  }
}

function editUserError(error) {
  return {
    type: EDIT_USER_ERROR,
    error,
  }
}

function fetchUserSuccess(payload) {
  return {
    type: FETCH_USER_SUCCESS,
    payload,
  }
}

function fetchUserError(error) {
  return {
    type: FETCH_USER_ERROR,
    error,
  }
}

export function useGetUserInfo(): Record<string, any> {
  return useSelector((state: AppState) => state.user.user)
}


export const useFetchUser = () => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback((): Promise<string> => {
    dispatch(userInfo(fetchUserPending()))

    const res = agent
      .get(`${API_HOST}/user/`)
      .then((response) => {
        dispatch(userInfo(fetchUserSuccess(response.body)))
        return 'Success'
      })
      .catch((error) => {
        dispatch(userInfo(fetchUserError(error)))
        if (error.status !== 401) {
          dispatch(updateSession({ type: 'SET_SESSION_EXPIRED', value:true }))
          return 'Unauthorized'
          // throw error
        }
        else {
          return `Server: ${error.response.status} ${error.response.statusText}`
        }
      })
    
    return res
  }, [dispatch])
}

// export const useSetSessionExpired = ():(value:boolean) => void => {
//   const dispatch = useDispatch<AppDispatch>()
//   return useCallback(
//     (value: boolean): void => {
//       dispatch(updateSession({ type: 'SET_SESSION_EXPIRED', value }))
//     },
//     [dispatch]
//   )
// }
