import {userInfo} from './actions'
import { createReducer } from '@reduxjs/toolkit'

import {
  EDIT_USER_PENDING,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  FETCH_USER_PENDING,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR
} from './actions'

const initialState: Record<string, any> = {
  isEditing: false,
  isLoading: true,
  error: null,
  user: null
}


export default createReducer(initialState, (builder) =>
  builder.addCase(userInfo, (state, action) => {
    const { type, payload } = action.payload
    switch(type){
      case EDIT_USER_PENDING:
        state.isEditing = true
        break
      case EDIT_USER_SUCCESS:
        state.isEditing = true
        state.user = payload
        state.error = null
        break
      case EDIT_USER_ERROR:
        state.isEditing = false
        state.error = payload
        break
      case FETCH_USER_PENDING:
        state.isLoading = true
        break
      case FETCH_USER_SUCCESS:
        state.isLoading = false
        state.user = payload
        state.error = null
        break
      case FETCH_USER_ERROR:
        state.isLoading = false
        state.error = payload
        break
      default:
        break
    }
  })
)



// export const getUser = state => state.user
// export const getError = state => state.error
// export const getIsEditing = state => state.isEditing
// export const getIsLoading = state => state.isLoading
