import styled from 'styled-components'
import { textTheme } from '../../theme-styled'

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 48px;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const LogoBox = styled.img`
  height: 3rem;
  //   aspect-ratio: 1;
`

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
export const SignInPanel = styled.div<{width?: string}>`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 80px;
  border-radius: 10px;
  text-align: center;
  width: ${(props) => props.width !== undefined ? props.width : '525px'};
  background-color: #2a2a52;
`

export const ContentTitle = styled.h1<{marginBottom?: string}>`
  font-family: "'Poppins'" important!;
  font-size: 40px;
  font-weight: 500;
  color: ${(props) => textTheme.primary};
  margin-top: 0px;
  margin-bottom: ${(props) => props.marginBottom !== undefined ? props.marginBottom : '2.5rem'};
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  text-align: center;
  width: 100%;
`

export const MessagePara = styled.p<{marginBottom?: string}>`
  color: ${(props) => textTheme.primary};
  margin-top: 0px;
  margin-bottom: ${(props) => props.marginBottom !== undefined ? props.marginBottom : '1rem'};
`

export const ErrorMessage = styled.div`
  color: ${(props) => textTheme.error};
  border: 1px solid #f5c6cb;
  border-radius: 0.5rem;
  background-color: #f8d7da;
  margin-top: 0px;
  margin-bottom: 1.5rem;
  padding: 0.75rem 1.25rem;
`

export const FormLable = styled.label`
  color: ${(props) => textTheme.primary};
  margin-top: 20px;
  margin-bottom: 8px;
  font-weight: bold;
`
export const FormInput = styled.input`
  border-radius: 10px;
  border: 1px solid #f7fbfd;
  background-color: transparent;
  text-align: center;
  padding: 8px 16px;
  height: 38px;
  width: 493px;
  font-size: 1.125rem;
  color: ${(props) => textTheme.primary};
`

export const FormButton = styled.button`
  weight: 172px;
  height: 43px;
  color: ${textTheme.primary};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 0.4rem 3rem 0.5rem 3rem;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  ${(props) => `
  background-color: ${props.theme.palette.primary.dark};
  color: ${props.theme.palette.text.primary};
  `}
  &:hover {
    background-color: #0069d9;
  }
`

export const IconWrapper = styled.div`
  margin-top: 20px;
  width: 106px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #e9f0f3;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckMailContent = styled.p`
  color: ${textTheme.primary};
  margin-top: 20px;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`

export const LoadingCircle = styled.div`
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
`

export const TextSpan = styled.span`
  color: ${textTheme.primary};
  font-size: 24px;
`

export const CodeInput = styled.input`
  border-radius: 10px;
  border: 1px solid #f7fbfd;
  background-color: transparent;
  text-align: center;
  padding: 8px 16px;
  height: 38px;
  width: 224px;
  font-size: 1.125rem;
  color: ${(props) => textTheme.primary};
`

export const MentionPara = styled.p`
  margin-top: 3rem;
  text-align: center;
  color: ${(props) => textTheme.primary};
`

export const Link = styled.a`
  text-align: center;
  color: ${(props) => textTheme.primary};
`
export const ErrorHint = styled.h2`
  text-align: center;
  color: ${(props) => textTheme.primary};
`

export const ImgBox = styled.img<{size?: string; magin?: string}>`
  height: ${(props) => props.size !== undefined ? props.size : '12rem'};
  aspect-ratio: 1;
  margin: ${(props) => props.magin !== undefined ? props.magin : '20px 0'};
`

export const SubTitle = styled.strong`
  text-align: center;
  color: ${(props) => textTheme.primary};
`

export const SubTextSpan = styled.span`
  text-align: center;
  color: ${(props) => textTheme.primary};
`
