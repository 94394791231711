import { useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router-dom'
import { Drawer, Divider, List, ListItem } from '@material-ui/core'

import { createStyles, Theme, makeStyles, styled } from '@material-ui/core/styles'

import ImageLogo from '../../assets/navbar/logo.png'
import Image1 from '../../assets/navbar/home.svg'
import Image1Des from '../../assets/navbar/home_des.svg'
import Image2 from '../../assets/navbar/filebox.svg'
import Image2Des from '../../assets/navbar/filebox_des.svg'
import { theme as themeStyled } from '../../theme-styled'

export const DrawerWidth = 72
export const ExtendDrawerWidth = 265

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DrawerWidth,
      flexShrink: 0,
      height: '100vh',
      overflowY: 'hidden',
      backgroundColor: themeStyled.main.dark,
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    drawerPaper: {
      width: DrawerWidth,
      border: '0px',
      backgroundColor: themeStyled.main.dark,
      overflowX: 'hidden',
    },
    imageLogo: {
      position: 'absolute',
      width: '42px',
    },
    iconLogo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '12px',
      height: '72px',
      marginBottom: '132px',
    },
    iconButton: {
      display: 'flex',
      justifyContent: 'left',
      padding: '25px',
      color: '#B0D0FF',
      fontSize: '13px',
      fontWeight: 700,
      // width: ExtendDrawerWidth,
      '&:hover': {
        backgroundColor: '#FFFFFF33',
      },
    },
    iconButtonCurrent: {
      color: '#3177FF',
      backgroundColor: themeStyled.secondary.dark,
      borderLeft: 'solid 4px',
      '&:hover': {
        backgroundColor: themeStyled.secondary.dark,
      },
    },
    logoFullName: {
      marginLeft: '25px',
    },
    iconBlock: {
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageIcon: {
      width: '24px',
    },
  })
)

const IconMapping = {
  home: {
    name: 'home',
    enable: Image1Des,
    normal: Image1,
  },
  history: {
    name: 'history',
    enable: Image2Des,
    normal: Image2,
  },
}

function getMainPath(path: string) {
  return path.split('/')[2]
}

const IconBlock = ({ iconName, enable }: { iconName: string; enable: boolean }) => {
  const classes = useStyles()
  const icon = IconMapping[iconName]

  return (
    <div className={classes.iconBlock}>
      <img alt={iconName} className={classes.imageIcon} src={enable ? icon.enable : icon.normal} />
    </div>
  )
}

const Navbar = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const mainPath = getMainPath(pathname)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (newValue: string) => {
    navigate(`../auth${newValue}`)
  }

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper),
        }}
        anchor="left"
      >
        <List>
          <ListItem
            button
            disableGutters
            className={classes.iconLogo}
            key={'logo'}
            onClick={() => handleClick('/auth/home')}
          >
            <img className={classes.imageLogo} src={ImageLogo} />
          </ListItem>

          {/* <div className={clsx(classes.drawerDivider, drawerOpen && 'drawerOpen')} /> */}

          {Object.keys(IconMapping).map((key) => (
            <ListItem
              button
              disableGutters
              className={clsx(classes.iconButton, mainPath === key && classes.iconButtonCurrent)}
              onClick={() => handleClick(`/${key}`)}
            >
              <IconBlock iconName={key} enable={mainPath === key} />
              {/* <DrawerExtendText isOpen={drawerOpen}>{BreadcrumbMapping['/home']}</DrawerExtendText> */}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  )
}

export default Navbar
