import {
  LoginWrapper,
  LogoBox,
  SignInPanel,
  ContentTitle,
  FormGroup,
  MessagePara,
  ErrorMessage,
  FormLable,
  CodeInput,
  FormButton,
  MentionPara,
  Link,
} from './styled'

import ImageWhiteLogo from '../../assets/logo/logo-naos.svg'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { postVerifyTOTP } from '../../utils/postVerifyTOTP'

const OTP = () => {
  const navigate = useNavigate()
  const [inputCode, setInputCode] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleVerifyOtpCode = async () => {
    const codePattern = /^\d{6}$/
    if (!codePattern.test(inputCode) || loading) {
      return
    }

    setLoading(true)
    try {
      const res = await postVerifyTOTP(inputCode)
      if (res) {
        setLoading(false)
        navigate('../../auth/home', { replace: true })
      }
    } catch (error:any) {
      setLoading(false)
      console.error(error)
      if(error && error.status === 401){
        // setSession(true)
        navigate('../../?error=expired', { replace: true })
      }
    }
  }

  return (
    <LoginWrapper>
      <LogoBox src={ImageWhiteLogo} />
      <SignInPanel>
        <ContentTitle>2-Step Verification</ContentTitle>
        <MessagePara>Enter the code generated by Google Authenticator.</MessagePara>
        <div>
          <FormGroup>
            <FormLable htmlFor="email">Verification Code</FormLable>
            <CodeInput
              type="number"
              onChange={(e) => {
                setInputCode(e.target.value)
              }}
            />
          </FormGroup>
          <FormButton onClick={handleVerifyOtpCode}>{loading ? 'Loading...' : 'Continue'}</FormButton>
        </div>
      </SignInPanel>
      <MentionPara>
        Unable to verify with your app? <Link href="mailto:support@naos.finance">Contact us</Link>
      </MentionPara>
    </LoginWrapper>
  )
}

export default OTP
