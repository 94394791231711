export enum BorrowingStauts {
  ONGOING = 1,
  END = 2,
  DEFAULT = 3,
}

export enum RepaymentStatus {
  PENDING = 1,
  PAID = 2,
  OVERDUE = 3,
}

export interface IInvestDetail {
  id: string
  name: string
  email: string
  amount: number
  time: string
}

export interface IBorrowingData {
  id: string
  amount: number
  startDate: string
  interestRate: number
  status: number
  transactionHash:string
}

export interface IRepaymentData {
  id: string
  amount: number
  repaymentDueDate: string
  repaymentDate: string
  status: number
  transactionHash:string
}

export interface IPenaltyData {
  id: string
  amount: number
  penaltyDueDate: string
  penaltyDate: string
  status: number
  transactionHash:string
}

export interface IInsuranceData {
  id: string
  amount: number
  paymentDueDate: string
  paymentDate: string
  status: number
  transactionHash:string
}

export type DataType =
  | keyof IInvestDetail
  | keyof IBorrowingData
  | keyof IRepaymentData
  | keyof IPenaltyData
  | keyof IInsuranceData

export type N = string | number
  
export interface HeadCell<T> {
  id: Exclude<keyof T, symbol>
  label: string
}

export const InvestDetailTitle: HeadCell<IInvestDetail>[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'amount', label: 'Amount' },
  { id: 'time', label: 'Time' },
]

export const BorrowingDataTitle: HeadCell<IBorrowingData>[] = [
  { id: 'id', label: 'ID' },
  { id: 'amount', label: 'Amount' },
  { id: 'startDate', label: 'Start Date' },
  { id: 'interestRate', label: 'Interest Rate' },
  { id: 'status', label: 'Status' },
  { id: 'transactionHash', label: 'Transaction Hash' },
]

export const RepaymentDataTitle: HeadCell<IRepaymentData>[] = [
  { id: 'id', label: 'ID' },
  { id: 'amount', label: 'Amount' },
  { id: 'repaymentDueDate', label: 'Repayment Due Date' },
  { id: 'repaymentDate', label: 'Repayment Date' },
  { id: 'status', label: 'Status' },
  { id: 'transactionHash', label: 'Transaction Hash' },
]

export const PenaltyDataTitle: HeadCell<IPenaltyData>[] = [
  { id: 'id', label: 'ID' },
  { id: 'amount', label: 'Amount' },
  { id: 'penaltyDueDate', label: 'Penalty Due Date' },
  { id: 'penaltyDate', label: 'Penalty Date' },
  { id: 'status', label: 'Status' },
  { id: 'transactionHash', label: 'Transaction Hash' },
]

export const InsuranceDataTitle: HeadCell<IInsuranceData>[] = [
  { id: 'id', label: 'ID' },
  { id: 'amount', label: 'Amount' },
  { id: 'paymentDueDate', label: 'Payment Due Date' },
  { id: 'paymentDate', label: 'Payment Date' },
  { id: 'status', label: 'Status' },
  { id: 'transactionHash', label: 'Transaction Hash' },
]
