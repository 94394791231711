import { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import { useHistory } from 'react-router'
import {
  useMediaQuery,
  useTheme,
  Typography,
  Divider,
  Link,
  Breadcrumbs,
  createStyles,
  Theme,
  makeStyles,
  Grid
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import AccountStatus from '../../common/AccountStatus'
// import WalletModal from '../modals/WalletModal'
import ImageLogo from '../../assets/navbar/logo.png'
import BreadcrumbMapping from '../../constants/breadcrumbMapping'

import { theme as themeStyled } from '../../theme-styled'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appHeader: {},
    root: {
      padding: '28px 0 0 0',
      width: '100%',
      maxHeight: '80px',
      backgroundColor: themeStyled.secondary.dark,
    //   marginLeft: '3.75vw',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      //   [theme.breakpoints.up(960)]: {
      //     paddingRight: '48px',
      //   },
    },
    divider: {
      marginTop: '30px',
      width: '100%',
      backgroundColor: theme.palette.text.primary,
      opacity: '0.12',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    imageLogo: {
      width: '30px',
      cursor: 'pointer',
    },
    breadWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    proLabel: {
      width: '3rem',
      height: '1rem',
      marginLeft: '0.5rem',
      textAlign: 'center',
      backgroundColor: 'rgba(255, 214, 0, 0.25)',
      border: '0.8px solid rgba(255, 214, 0, 0.3)',
      borderRadius: '4px',
      padding: '0.4px 0.4px',
      position: 'relative',
      color: '#FFC700',
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'italic',
      letterSpacing: '0.15px',
      lineHeight: '16px',
    },
    contentWrapper: {
      position: 'absolute',
      width: '1.75rem',
      height: '100%',
      background: 'linear-gradient(108.87deg, rgba(222, 212, 160, 0.7) 1.53%, rgba(249, 244, 220, 0) 86.56%)',
      borderRadius: '3px',
    },
  })
)

// todo - generate breadcrumbs by location directly

const Header = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()

  const [locationList, setLocationList] = useState<string[]>([])

  useEffect(() => {
    const parseLocation = () => {
      let arr: string[] = []
      location.pathname
        .split('/')
        .slice(2)
        .forEach((a, index) => {
          if (index === 0) return arr.push('/' + a)
          arr.push(arr[index - 1] + '/' + a)
        })
      return arr
    }
    setLocationList(parseLocation())
  }, [location.pathname])

  const showBreadCrumbs = useMemo(() => {
    const breadCrumbsComponents = locationList.map((l) => {
      return (
        <Link href={`/auth${l}`} key={`bread-${l}`}>
          <Typography variant="h2">{BreadcrumbMapping[l]}</Typography>
        </Link>
      )
    })
    return (
      <Breadcrumbs separator={<NavigateNextIcon color="secondary" fontSize="small" />} aria-label="breadcrumb">
        {breadCrumbsComponents}
      </Breadcrumbs>
    )
  }, [locationList])
 
  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          {showBreadCrumbs}
          <AccountStatus />
        </div>
        <Divider light={true} className={classes.divider} />
      </div>
      {/* <WalletModal /> */}
    </>
  )
}

export default Header
