import {
  LoginWrapper,
  LoadingWrapper,
  LogoBox,
  SignInPanel,
  ContentTitle,
  MessagePara,
  ErrorMessage,
  FormButton,
  TextSpan,
  ErrorHint,
  ImgBox,
  SubTitle,
  SubTextSpan,
} from './styled'

import ImageWhiteLogo from '../../assets/logo/logo-naos.svg'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import { theme } from '../../theme-styled'
import { postSetupTOTP } from '../../utils/postSetupTOTP'
import { useSetSessionExpired } from '../../redux/session/hooks'

const OtpSetup = () => {
  const navigate = useNavigate()
  const setSessionExpired = useSetSessionExpired()
  // const login_token = useGetToken()
  const [otpQrUrl, setOtpQrUrl] = useState<string>('')
  const [otpKey, setOtpKey] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (otpKey === '' && otpQrUrl === '') {
      setLoading(true)
      handleSetup()
    }
  }, [])

  const handleSetup = async () => {
    try {
      const response = await postSetupTOTP()
      if (response) {
        setOtpQrUrl(response.otpQrUrl)
        setOtpKey(response.otpKey)
        setLoading(false)
      }
    } catch (error: any) {
      console.error(error)
      if (error && error.status === 401) {
        setSessionExpired(true)
        // navigate('../../?error=expired', { replace: true })
      }
      setErrorMessage('An error occurred configuring OTP.')
    }
  }

  return (
    <LoginWrapper>
      <LogoBox src={ImageWhiteLogo} />
      <SignInPanel width={!errorMessage && !loading ? '700px' : '525px'}>
        {errorMessage ? (
          <LoadingWrapper>
            <ErrorHint>{errorMessage}</ErrorHint>
          </LoadingWrapper>
        ) : loading ? (
          <LoadingWrapper>
            <BounceLoader color={theme.loading} size={60} />
            <TextSpan>Loading...</TextSpan>
          </LoadingWrapper>
        ) : (
          <>
            <ContentTitle marginBottom="1rem">Scan QR Code</ContentTitle>
            <MessagePara>
              Open Google Authenticator and scan this image. You can also manually enter your key.
            </MessagePara>
            <ImgBox src={otpQrUrl} />
            <MessagePara>
              <SubTitle>Secret Key</SubTitle>
              <br />
              <SubTextSpan>{otpKey}</SubTextSpan>
            </MessagePara>
            <ErrorMessage>Store this secret key somewhere safe and don&apos;t share it with anyone else.</ErrorMessage>

            <FormButton
              onClick={() => {
                navigate('../', { replace: true })
              }}
            >
              Continue
            </FormButton>
          </>
        )}
      </SignInPanel>
    </LoginWrapper>
  )
}

export default OtpSetup
