import {
  LoginWrapper,
  LogoBox,
  SignInPanel,
  ContentTitle,
  IconWrapper,
  CheckMailContent,
  LoadingWrapper,
  TextSpan,
} from './styled'

import ImageWhiteLogo from '../../assets/logo/logo-naos.svg'
import { Mail } from 'react-feather'
import { useState, useEffect } from 'react'
import { BounceLoader } from 'react-spinners'
import { theme } from '../../theme-styled'
import { useNavigate, useLocation } from 'react-router-dom'
import { postVerifyEmailToken } from '../../utils/postVerifyEmailToken'
import { useSetSessionExpired } from '../../redux/session/hooks'
import { getNextOnboardingPage } from '../../utils'

const HandleLogin = () => {
  const navigate = useNavigate()
  const setSessionExpired = useSetSessionExpired()
  const location = useLocation()
  const queryToken = location.pathname.split('/').pop()

  useEffect(() => {
    if (queryToken) {
      handleLoginToken(queryToken)
    } else {
      navigate('../', { replace: true })
    }
  }, [queryToken])

  const handleLoginToken = async (loginToken: string) => {
    try {
      const res = await postVerifyEmailToken(loginToken)
      if(res){
        const nextOnboardingPage = getNextOnboardingPage(res)
        navigate(`..${nextOnboardingPage}`, { replace: true })
      }
    } catch (error: any) {
      console.error(error)
      if(error && error.status === 401){
        navigate('../?error=expired', { replace: true })
      }
      return
    }
  }

  return (
    <LoginWrapper>
      <LogoBox src={ImageWhiteLogo} />
      <SignInPanel>
        <LoadingWrapper>
          <BounceLoader color={theme.loading} size={60} />
          <TextSpan>Loading...</TextSpan>
        </LoadingWrapper>
      </SignInPanel>
    </LoginWrapper>
  )
}

export default HandleLogin