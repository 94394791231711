import styled from "styled-components"

export const MainPanel = styled.div`
width: calc(100% - 72px);
height: 100%;
display: flex;
flex-direction: column;
border: none;
border-radius: 40px 0px 0px 40px;
background-color: ${(prop) => prop.theme.secondary.dark};
padding-left: 3.75vw;
`

export const ContentWrapper = styled.div`
width: 100%
height: 100%;

`
