import { API_HOST } from '../constants/endpoints'
import agent from './agent'

export async function postVerifyTOTP(otp_code: string): Promise<Record<string, any>> {
  const res = await agent.post(`${API_HOST}/login/verify_totp`).send({ code: otp_code })
  if (res && res.body) {
    return res.body
  }
  throw new Error('failed to verified totp')
}
