import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
// import { useWeb3React } from '@web3-react/core'
// import { utils } from 'ethers'
import {
  useMediaQuery,
  useTheme,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
} from '@material-ui/core'

import GradientCard from '../../common/GradientCard'
import GradientBackground from '../../common/GradientBackground'
import ActionBoard from '../../common/ActionBoard'
import { MainButton } from '../../common/buttons'
import {
  TitleDiv,
  TitleFront,
  TitleIcon,
  TitleTextDiv,
  TitleBackContainer,
  TitleBack,
  Basic,
  BasicContainer,
  BasicText,
  BasicBottom,
  BasicBottomLeft,
  BasicBottomRight,
  PoolContainer,
  PoolFlex,
  PoolFlexCenter,
  Button,
  SubButton,
  Pool,
  AddressInfoBox,
  PoolFlexWrapper,
  ContentFlex,
} from './styled'
// import { MapIcon } from '../../common/MapIcon'
import TransmuteCoin from '../../assets/symbol/coin.png'
import { theme as themeStyled } from '../../theme-styled'
import { ChainId } from '../../constants/blockchain'

import {
  BorrowingDataTitle,
  RepaymentDataTitle,
  PenaltyDataTitle,
  InsuranceDataTitle,
} from '../../constants/data_interface'
import { useOutletContext } from 'react-router-dom'
import EnhancedTableWrapper from '../../common/EnhancedTableWrapper'
import { X as CloseIcon } from 'react-feather'

interface StyledTabsProps {
  value: number
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

interface StyledTabProps {
  label: string
}

const AntTabs = withStyles({
  root: {
    width: '100%',
    borderBottom: `1px solid ${themeStyled.status.inactive}`,
  },
  indicator: {
    backgroundColor: themeStyled.status.active,
  },
})((props: StyledTabsProps) => <Tabs {...props} />)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#ffffff',
    },
    '&:hover': {
      color: '#1D77FF',
      opacity: 1,
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '90.625vw',
      height: '88.15vh',
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    titleButtonExit: {
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '8px',
      '&:hover': {
        borderRadius: '16px',
      },
    },
    titleMargin: {
      marginBottom: 10,
    },
    disableTitleButtonExit: {
      color: 'rgba(255, 255, 255, 0.2)',
      border: `1px solid rgba(255, 255, 255, 0.2)`,
      backgroundColor: 'transparent',
      marginRight: '8px',
    },
    transStatusDetial: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '16px 0 16px 16px',
      [theme.breakpoints.down(960)]: {
        padding: '16px 0 0 0',
      },
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      maxWidth: 280,
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '80px',
      },
    },
    disableText: {
      color: 'rgba(255, 255, 255, 0.2)',
    },
    dividerVertical: {
      width: '1px',
      backgroundColor: theme.palette.text.primary,
      opacity: '60%',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    modalPaper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: themeStyled.main.dark,
      width: 528,
      height: 682,
    },
    modalTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // cursor: 'pointer',
    },
  })
)

const History = () => {
  const context: Record<string, any> = useOutletContext()
  const chainId = ChainId.BSC
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean[]>([false])
  const [tabs, setTabs] = useState<number>(0)

  const handleTabChannge = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabs(newValue)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.informationBox}>
          <TitleDiv>
            <TitleFront>
              <TitleIcon src={TransmuteCoin} />
              <TitleTextDiv>
                <Typography variant="body2" className={classes.greyHeader}>
                  NAOS{' '}
                </Typography>
                <Typography variant="h3">{context.assetData.name} Assets Management</Typography>
              </TitleTextDiv>
            </TitleFront>
            {/* <TitleBackContainer>
              <TitleBack
                className={stakeNusd.lte(0) ? classes.disableTitleButtonExit : classes.titleButtonExit}
                onClick={() => stakeNusd.gt(0) && openDrawer(TransmuterAction.EXIT)}
              >
                <Typography variant="body1" color="inherit">
                  EXIT
                </Typography>
              </TitleBack>
              <TitleBack className={classes.titleButton} onClick={() => openDrawer(TransmuterAction.STAKE)}>
                <Typography variant="body1">STAKE</Typography>
              </TitleBack>
            </TitleBackContainer> */}
          </TitleDiv>

          <Pool>
            <PoolFlex>
              <PoolFlexWrapper style={{ width: '100%' }}>
                <AntTabs value={tabs} onChange={handleTabChannge}>
                  <AntTab label="Borrowing History" />
                  <AntTab label="Repayment History" />
                  <AntTab label="Penalty History" />
                  <AntTab label="Insurance History" />
                </AntTabs>
                <div style={{ height: '16px' }} />
                {tabs === 0 && (
                  <EnhancedTableWrapper
                    height={'528px'}
                    tableTitle={BorrowingDataTitle}
                    data={context.borrowingData}
                    initialOrderBy={'startDate'}
                    dataStatus={'borrow'}
                  />
                )}
                {tabs === 1 && (
                  <EnhancedTableWrapper
                    height={'528px'}
                    tableTitle={RepaymentDataTitle}
                    data={context.repaymentData}
                    initialOrderBy={'repaymentDueDate'}
                    dataStatus={'repayment'}
                  />
                )}
                {tabs === 2 && (
                  <EnhancedTableWrapper
                    height={'528px'}
                    tableTitle={PenaltyDataTitle}
                    data={context.penaltyData}
                    initialOrderBy={'penaltyDueDate'}
                    dataStatus={'penalty'}
                  />
                )}
                {tabs === 3 && (
                  <EnhancedTableWrapper
                    height={'528px'}
                    tableTitle={InsuranceDataTitle}
                    data={context.insuranceData}
                    initialOrderBy={'paymentDueDate'}
                    dataStatus={'Insurance'}
                  />
                )}
              </PoolFlexWrapper>
            </PoolFlex>
            <div className={classes.bottomSpace}></div>
          </Pool>

          <div className={classes.bottomSpace}></div>
        </div>
      </div>
    </>
  )
}

export default History
