import { useCallback } from 'react'
import { AppState, AppDispatch } from '../store'
import { useSelector, useDispatch } from 'react-redux'
import { updateSession } from './actions'

export function useGetSessionExpired(): boolean {
  return useSelector((state: AppState) => state.session.expired)
}

export const useSetSessionExpired = ():(value:boolean) => void => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(
    (value: boolean): void => {
      dispatch(updateSession({ type: 'SET_SESSION_EXPIRED', value }))
    },
    [dispatch]
  )
}
