import { updateChainId } from './actions'
import { createReducer } from '@reduxjs/toolkit'
import { ChainId } from '../../constants/blockchain'

const initialState: Record<string, number> = {
  chainId: ChainId.BSC,
}

export default createReducer(initialState, (builder) =>
  builder.addCase(updateChainId, (state, action) => {
    const { chainId } = action.payload
    if (!isNaN(chainId) && chainId !== undefined) {
      state.chainId = chainId
    }
  })
)

