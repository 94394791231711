import React, { useState, useMemo, useEffect } from 'react'
import {
  useMediaQuery,
  makeStyles,
  useTheme,
  Theme,
  Typography,
  createStyles,
  Table,
  Divider,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'

// import { BigNumber } from 'ethers'
import { N, HeadCell, BorrowingStauts, RepaymentStatus } from '../../constants/data_interface'
import { BasicContainer, FilterControlWrapper, ListContentWrapper, ButtonWrapper } from './styled'
import TablePaginationActions from './TablePaginationActions'
import gradientImg from '../../assets/symbol/gradient_rectangle.png'
import GradientBackground from '../GradientBackground'
import numeral from 'numeral'
import { theme as themeStyled } from '../../theme-styled'
import { X as CloseIcon } from 'react-feather'
import { displayAddrString } from '../../utils/displayAddrString'
import { TransactionLink } from '../ExternalLink'
import { ChainId } from '../../constants/blockchain'

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = <T,>(array: T[], comparator: (a: T, b: T) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface EnhanedTableProps<T> {
  classes: ReturnType<typeof useStyles>
  heads: HeadCell<T>[]
  order: Order
  orderBy: keyof T
  onRequestSort: (property: keyof T) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {},
    tr: {
      // textAlign: 'center',
      borderBottom: '1px solid rgba(255, 255, 255, .12)',
    },
    thover: {
      height: 49,
      [theme.breakpoints.down(960)]: {
        height: 81,
      },
      '&:hover': {
        backgroundImage: `url(${gradientImg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      },
    },
    pagination: {
      borderBottom: '1px solid rgba(255, 255, 255, .12)',
    },
    modalPaper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      // textAlign: 'left',
      backgroundColor: '#2A2A52',
      width: 528,
      height: 638,
    },
    modalTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 480,
      padding: '24px 24px',
      // cursor: 'pointer',
    },
    modalDivider: {
      width: '100%',
      height: '1px',
      margin: '0px auto',
      marginBottom: '16px',
      backgroundColor: theme.palette.text.primary,
      opacity: '12%',
    },
    modalContent: {
      width: 480,
      height: '100%',
      padding: '24px 24px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  })
)

const EnhancedTableHead = <T,>(props: EnhanedTableProps<T>) => {
  const { classes, heads, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(property)
  }

  return (
    <TableHead>
      <TableRow>
        {heads.map((head) => (
          <TableCell
            key={head.id}
            align="right"
            className={classes.tr}
            sortDirection={orderBy === head.id ? order : false}
          >
            {head.id === 'interestRate' || head.id === 'id' ? (
              <>{head.label}</>
            ) : (
              <TableSortLabel
                active={orderBy === head.id}
                direction={orderBy === head.id ? order : 'asc'}
                onClick={createSortHandler(head.id)}
              >
                {head.label}
              </TableSortLabel>
            )}
            {/*  */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const rowsPerPage = 8

const EnhancedTableWrapper = <T,>({
  height,
  tableTitle,
  data,
  initialOrderBy,
  dataStatus,
  chainId,
  openDetail,
  setDetail,
}: {
  height: string
  tableTitle: HeadCell<T>[]
  data: { [key in keyof T]: string | number }[]
  initialOrderBy: keyof T
  dataStatus?: string
  chainId?: number
  openDetail?: () => void
  setDetail?: (data: T) => void
}) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean[]>([false])
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof T>(initialOrderBy)

  const handleModalOpen = () => {
    if (dataStatus === 'repayment') setIsModalOpen([true])
  }
  const colSpan = useMemo(() => {
    return tableTitle.length
  }, [tableTitle])

  const showSortData = useMemo(() => {
    return data.length === 0
      ? ''
      : stableSort(data, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <TableRow className={classes.thover} key={index}>
              {Object.keys(row).map((content) => {
                if (row[content] !== null) {
                  switch (content) {
                    case 'startDate':
                    case 'repaymentDate':
                    case 'repaymentDueDate':
                    case 'paymentDate':
                    case 'paymentDueDate':
                      return (
                        <TableCell key={content} align="right" className={classes.tr}>
                          {row[content].split('T')[0]}
                        </TableCell>
                      )
                    case 'amount':
                      return (
                        <TableCell key={content} align="right" className={classes.tr} style={{ marginLeft: '1rem' }}>
                          <div>$ {numeral(row[content]).format('0,0')}</div>
                        </TableCell>
                      )
                    case 'status':
                      if (dataStatus === 'borrow') {
                        switch (row[content]) {
                          case BorrowingStauts.ONGOING:
                            return (
                              <TableCell key={content} align="right" className={classes.tr}>
                                Ongoing
                              </TableCell>
                            )
                          case BorrowingStauts.END:
                            return (
                              <TableCell key={content} align="right" className={classes.tr}>
                                End
                              </TableCell>
                            )
                          case BorrowingStauts.DEFAULT:
                            return (
                              <TableCell key={content} align="right" className={classes.tr}>
                                Default
                              </TableCell>
                            )
                        }
                      } else if (dataStatus === 'repayment' || dataStatus === 'penalty' || dataStatus === 'Insurance') {
                        switch (row[content]) {
                          case RepaymentStatus.PENDING:
                            return (
                              <TableCell key={content} align="right" className={classes.tr}>
                                Pending
                              </TableCell>
                            )
                          case RepaymentStatus.PAID:
                            return (
                              <TableCell key={content} align="right" className={classes.tr}>
                                Paid
                              </TableCell>
                            )
                          case RepaymentStatus.OVERDUE:
                            return (
                              <TableCell key={content} align="right" className={classes.tr}>
                                Overdue
                              </TableCell>
                            )
                        }
                      }
                      return (
                        <TableCell key={content} align="right" className={classes.tr}>
                          {row[content]}
                        </TableCell>
                      )
                    case 'interestRate':
                      return (
                        <TableCell key={content} align="right" className={classes.tr}>
                          {row[content]}%
                        </TableCell>
                      )
                    case 'id':
                      return (
                        <TableCell key={content} align="right" className={classes.tr}>
                          {displayAddrString(row[content])}
                        </TableCell>
                      )
                    case 'transactionHash':
                      return (
                        <TableCell key={content} align="right" className={classes.tr}>
                          {row[content] !== null ? (
                            <TransactionLink hash={row[content]}>
                              {displayAddrString(row[content], 8, 8)}
                            </TransactionLink>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      )
                    case 'assetId':
                    case 'createdAt':
                    case 'updatedAt':
                    case 'asset_id':
                      return ''
                    default:
                      return (
                        <TableCell key={content} align="right" className={classes.tr}>
                          {row[content]}
                        </TableCell>
                      )
                  }
                } else {
                  return (
                    <TableCell key={content} align="right" className={classes.tr}>
                      {''}
                    </TableCell>
                  )
                }
              })}
            </TableRow>
          ))
  }, [data, page, order, orderBy])

  const showEmptyRows = useMemo(() => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
    if (emptyRows === 0) return <tr></tr>
    return (
      <TableRow style={{ height: 48 * emptyRows }}>
        <TableCell key="emptyRows" colSpan={colSpan} className={classes.tr} />
      </TableRow>
    )
  }, [data, page])

  const handleRequestSort = (property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  return (
    <>
      <GradientBackground width="100%" height={height}>
        <BasicContainer>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              heads={tableTitle}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {showSortData}
              {showEmptyRows}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={colSpan}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                  className={classes.tr}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </BasicContainer>
      </GradientBackground>

      <Dialog
        classes={{
          paper: classes.modalPaper,
        }}
        open={isModalOpen[0]}
        onClose={() => setIsModalOpen([false])}
      >
        <DialogTitle
          disableTypography
          classes={{
            root: classes.modalTitle,
          }}
        >
          <Typography variant="h3">Details</Typography>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setIsModalOpen([false])} />
        </DialogTitle>
        <Divider orientation="horizontal" className={classes.modalDivider} />
        <DialogContent
          classes={{
            root: classes.modalContent,
          }}
        >
          <FilterControlWrapper></FilterControlWrapper>
          <ListContentWrapper></ListContentWrapper>
          <ButtonWrapper></ButtonWrapper>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EnhancedTableWrapper
