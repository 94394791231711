import {
  LoginWrapper,
  LogoBox,
  TitleWrapper,
  TitleContent,
  SignInPanel,
  ContentTitle,
  IconWrapper,
  CheckMailContent,
} from './styled'

import ImageWhiteLogo from '../../assets/logo/logo-naos.svg'
import { Mail } from 'react-feather'
import { useState, useEffect } from 'react'

const EmailCheck = () => {
  return (
    <LoginWrapper>
      <LogoBox src={ImageWhiteLogo} />
      <SignInPanel>
        <ContentTitle>Check Your Email</ContentTitle>
        <IconWrapper>
          <Mail size={58} style={{ color: ' #007aff' }} />
        </IconWrapper>
        <CheckMailContent>
          If this was a valid email address, an email will be delivered within a few minutes.
          <br />
          Please click the link in the email to access your account.
        </CheckMailContent>
      </SignInPanel>
    </LoginWrapper>
  )
}

export default EmailCheck
