import path from 'path'
import fs from 'fs'

export const IS_DEV = process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_IS_DEV === 'true' : true

const NODE_ENV = process.env.NODE_ENV
if (!NODE_ENV) {
  throw new Error(
    'The NODE_ENV environment variable is required but was not specified.'
  )
}


function getClientEnvironment(publicUrl: string) {
  const raw = Object.keys(process.env).reduce(
    (env, key) => {
      env[key] = process.env[key]
      return env
    },
    {
      // Useful for determining whether we’re running in production mode.
      // Most importantly, it switches React into the correct mode.
      NODE_ENV: process.env.NODE_ENV || 'development',
      // Useful for resolving the correct path to static assets in `public`.
      // For example, <img src={process.env.PUBLIC_URL + '/img/logo.png'} />.
      // This should only be used as an escape hatch. Normally you would put
      // images into the `src` and `import` them in code to get their paths.
      PUBLIC_URL: publicUrl,
    }
  )
  // Stringify all values so we can feed into Webpack DefinePlugin
  const stringified = {
    'process.env': Object.keys(raw).reduce((env, key) => {
      const whitelist = [
        // 'CLIENT_SENTRY_DSN',
        'TEAM_API_URL',
        // 'ENABLE_GA',
        // 'EARN_OGN_ENABLED',
        'INVESTOR_API_URL',
        // 'LOCKUP_BONUS_RATE',
        'NODE_ENV',
        // 'OTC_REQUEST_ENABLED',
        // 'INVESTOR_UNLOCK_DATE',
        // 'TEAM_UNLOCK_DATE',
        // 'STAKE_URL',
        'API_URL',
      ]
      if (whitelist.includes(key)) {
        env[key] = JSON.stringify(raw[key])
      }
      return env
    }, {}),
  }

  return { raw, stringified }
}

export default getClientEnvironment
