import { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { MainPanel, ContentWrapper } from './styled'
import Navbar from '../Navbar'
import Header from '../Header'
import { useGetSessionExpired, useSetSessionExpired } from '../../redux/session/hooks'
import { useFetchUser } from '../../redux/user/hooks'
import { Outlet } from 'react-router-dom'

import { LoginWrapper, LogoBox, SignInPanel, LoadingWrapper, TextSpan } from '../../page_common/Signin/styled'
import ImageWhiteLogo from '../../assets/logo/logo-naos.svg'
import { theme } from '../../theme-styled'
import { BounceLoader } from 'react-spinners'
import agent from '../../utils/agent'
import { API_HOST } from '../../constants/endpoints'
import useSWR from 'swr'

const fetcher = (url: string) => agent.get(url).then((res) => res.body)

const Auth = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const fetchUser = useFetchUser()
  const setSessionExpired = useSetSessionExpired()
  const getSessionExpired = useGetSessionExpired()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [fetchStatus, setFetchStatus] = useState<string>('Success')

  const { data: assetData, error: assetError } = useSWR(
    fetchStatus === 'Success' ? `${API_HOST}/asset/` : null,
    fetcher
  )
  const { data: borrowingData, error: borrowingError } = useSWR(
    fetchStatus === 'Success' ? `${API_HOST}/asset/history/borrow` : null,
    fetcher
  )
  const { data: repaymentData, error: repaymentError } = useSWR(
    fetchStatus === 'Success' && borrowingData ? `${API_HOST}/asset/history/repayment` : null,
    fetcher
  )
  const { data: insuranceData, error: insuranceError } = useSWR(
    fetchStatus === 'Success' && repaymentData ? `${API_HOST}/asset/history/insurance` : null,
    fetcher
  )
  const { data: penaltyData, error: penaltyError } = useSWR(
    fetchStatus === 'Success' && insuranceData ? `${API_HOST}/asset/history/penalty` : null,
    fetcher
  )

  setSessionExpired(false)

  useEffect(() => {
    fetchUser().then(setFetchStatus).catch(setFetchStatus)
  }, [])

  useEffect(() => {
    if (assetData && borrowingData && repaymentData && penaltyData && insuranceData && !getSessionExpired) {
      setIsLoading(false)
    } else if (fetchStatus === 'Success' && (assetError || borrowingError || repaymentError || penaltyError || insuranceError)) {
      navigate(`../../?error=server`, { replace: true })
    }
  }, [assetData, borrowingData, repaymentData, penaltyData, assetError, borrowingError, repaymentError, penaltyError])

  if (getSessionExpired) {
    setIsLoading(true)
    const pathLen = location.pathname.split('/').length

    let pathPrefix = ''
    for (let i = 0; i < pathLen; i++) {
      pathPrefix += '../'
    }

    navigate(`${pathPrefix}?error=expired`, { replace: true })
  }

  return isLoading ? (
    <LoginWrapper>
      <LogoBox src={ImageWhiteLogo} />
      <SignInPanel>
        <LoadingWrapper>
          <BounceLoader color={theme.loading} size={60} />
          <TextSpan>Loading...</TextSpan>
        </LoadingWrapper>
      </SignInPanel>
    </LoginWrapper>
  ) : (
    <>
      <Navbar />
      <MainPanel>
        <Header />
        <ContentWrapper>
          <Outlet
            context={{
              assetData,
              borrowingData: borrowingData['history'],
              repaymentData: repaymentData['history'],
              penaltyData: penaltyData['history'],
              insuranceData: insuranceData['history'],
            }}
          />
        </ContentWrapper>
      </MainPanel>
    </>
  )
}

export default Auth
