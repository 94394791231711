import {
  LoginWrapper,
  LogoBox,
  SignInPanel,
  ContentTitle,
  MessagePara,
  FormButton,
  ImgBox,
  Link,
} from './styled'

import ImageWhiteLogo from '../../assets/logo/logo-naos.svg'
import GoogleAuthenticatorIcon from '../../assets/logo/google-authenticator.svg'
import { useNavigate } from 'react-router-dom'

const OtpSetup = () => {
  const navigate = useNavigate()

  return (
    <LoginWrapper>
      <LogoBox src={ImageWhiteLogo} />
      <SignInPanel>
        <ContentTitle marginBottom="0.5rem">Set Up Google Authenticator</ContentTitle>
        <ImgBox src={GoogleAuthenticatorIcon} size="100px" />
        <MessagePara>
          Google Authenticator will generate a unique, time-sensitive security code you can use to secure your account.
        </MessagePara>
        <MessagePara marginBottom="3rem">
          To get started, click continue once you have the{' '}
          <Link href="https://support.google.com/accounts/answer/1066447" target="_blank" rel="noopener noreferrer">
            Google Authenticator
          </Link>{' '}
          app installed.
        </MessagePara>
        <FormButton
          onClick={() => {
            navigate('../setup', { replace: true })
          }}
        >
          Continue
        </FormButton>
      </SignInPanel>
    </LoginWrapper>
  )
}

export default OtpSetup
