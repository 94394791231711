export const getNextOnboardingPage = (user) => {
  if (user.body.otpVerified) {
    // Verified OTP can no longer perform any onboarding as OTP would be required
    // for any login action
    return '/otp'
  } else {
    // Only remaining step is OTP setup
    return '/otp/explain'
  }
}
