import {
  LoginWrapper,
  LogoBox,
  TitleWrapper,
  TitleContent,
  SignInPanel,
  ContentTitle,
  FormGroup,
  MessagePara,
  ErrorMessage,
  FormLable,
  FormInput,
  FormButton,
} from './styled'
import { Typography } from '@material-ui/core'

import ImageLogo from '../../assets/navbar/logo.png'
import ImageWhiteLogo from '../../assets/logo/logo-naos.svg'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { postSendEmail } from '../../utils/postSendEmail'
import { useSetSessionExpired } from '../../redux/session/hooks'
import { ContactSupportOutlined } from '@material-ui/icons'

const Signin = () => {
  const [messageElement, setMessageElement] = useState<JSX.Element>(<></>)
  const [loading, setLoading] = useState<boolean>(false)
  const [inputEmail, setInputEmail] = useState<string>('')
  const navigate = useNavigate()
  const setSessionExpired = useSetSessionExpired()

  const query = new URLSearchParams(window.location.search)
  const error = query.get('error')

  useEffect(() => {
    if (!error) {
      setMessageElement(<MessagePara>We will send you a magic link to your email to confirm access.</MessagePara>)
    } else if (error === 'expired') {
      setMessageElement(<ErrorMessage>Your login token is invalid or has expired. Please login again.</ErrorMessage>)
    } else if (error === 'server') {
      setMessageElement(
        <ErrorMessage>An error occurred communicating with the server. Please try again later.</ErrorMessage>
      )
    }
  }, [error])

  const handleEmailToken = async () => {
    const emailPattern = /.+@.+\..+/
    if (!emailPattern.test(inputEmail)) {
      return
    }

    setLoading(true)

    try {
      const res = await postSendEmail(inputEmail)
      if (res) {
        setLoading(false)
        navigate('../check_email', { replace: true })
      }
      return
    } catch (error: any) {
      setLoading(false)
      console.error(error)
      if (error && error.status === 401) {
        setSessionExpired(true)
        navigate('..?error=expired', { replace: true })
      }
      else if (error) {
        navigate('..?error=server', { replace: true })
      }
      return
    }
  }

  return (
    <LoginWrapper>
      <LogoBox src={ImageWhiteLogo} />
      <SignInPanel>
        <ContentTitle>Sign In</ContentTitle>
        {messageElement}
        {/* <form> */}
        <FormGroup>
          <FormLable htmlFor="email">Email Address</FormLable>
          <FormInput
            type="email"
            onChange={(e) => {
              setInputEmail(e.target.value)
            }}
          />
        </FormGroup>
        <FormButton onClick={handleEmailToken}>{loading ? 'Loading...' : 'Continue'}</FormButton>
        {/* </form> */}
      </SignInPanel>
    </LoginWrapper>
  )
}

export default Signin
