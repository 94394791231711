export enum ChainId {
    MAINNET = 1,
    ROPSTEN = 3,
    RINKEBY = 4,
    GÖRLI = 5,
    KOVAN = 42,
    BSC = 56,
    BSC_TEST = 97,
  }
  
  export const SUPPORTED_CHAIN_IDS = [
    ChainId.MAINNET,
    ChainId.ROPSTEN,
    ChainId.RINKEBY,
    ChainId.KOVAN,
    ChainId.BSC,
    ChainId.BSC_TEST,
  ]
  
  export const SEC_PER_BLOCK = {
    BSC: 3,
    ETH: 13.5,
  }
  
  export const SEC_PER_BLOCK_CHAIN = {
    [ChainId.BSC]: 3,
    [ChainId.MAINNET]: 13.5,
    [ChainId.RINKEBY]: 13.5,
  }
  