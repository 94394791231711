import styled from 'styled-components'

export const BasicContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
`

export const FilterControlWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
export const ListContentWrapper = styled.div`
  width: 100%;
  hieght: 411px;
  overflow-y: auto;
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
